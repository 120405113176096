import React from 'react';
import "typeface-montserrat-subrayada";
import { StaticImage } from "gatsby-plugin-image"
import Footer from '../components/footer/footer'

const HomePage = (props: any) => {
  const { data } = props;


  return (
    <div>
      <div>
        <div style={{position:"relative",height:"100%",zIndex:1}}>
          <div style={{
              top: "0",
              left: "0",
              width: "auto",
              height: "auto",
              minWidth: "100%",
              minHeight: "100%",
              maxWidth: "inherit",
          }}>
          </div>
        </div>

        <div style={{borderColor:"5px", position:"absolute",margin:"30% 0 0 10vw", width:"20vw",zIndex:5}}>
          <a href="#" style={{display:"block",zIndex:5}}>

          </a>
        </div>

        <div>
          <div style={{position:"absolute",margin:"5% 0 0 5vw", zIndex:5}}>
          <a href="/themeset"><StaticImage style={{position:"absolute", width:"45vw"}} src="../images/top/logo_l2.png" alt="img" /></a>
          </div>

          <div style={{position:"absolute",margin:"40% 0 0 5vw", zIndex:5, width:"9vw"}}>
          <a href="https://www.teikokushoin.co.jp/"><StaticImage src="../images/top/syoei1.png" alt="img" /></a>
          </div>

          <div style={{position:"absolute",margin:"42% 0 0 16vw", zIndex:5, width:"9vw"}}>
          <a href="https://www.teikokushoin.co.jp/"><StaticImage src="../images/top/syoei2.png" alt="img" /></a>
          </div>

          <div style={{position:"absolute",margin:"42% 0 0 27vw", zIndex:5, width:"9vw"}}>
          <a href="https://www.teikokushoin.co.jp/"><StaticImage src="../images/top/syoei3.png" alt="img" /></a>
          </div>

        </div>
        <div>

<div style={{position:"absolute",margin:"5% 0 0 75vw", zIndex:5, width:"17vw"}}>
          <a href="/saas"><StaticImage src="../images/top/logo_b.png" alt="img" /></a>
          </div>

<div style={{position:"absolute",margin:"5% 0 0 55vw", zIndex:5, width:"17vw"}}>
          <a href="/themeset"><StaticImage src="../images/top/logo_d.png" alt="img" /></a>
          </div>

        </div>
      </div>

      <StaticImage src="../images/top/top_l5.png" alt="img" width={2000}/>
      <Footer>
          Copyright &copy; {new Date().getFullYear()}
          <a href="https://www.teikokushoin.co.jp/"> 帝国書院</a>
          <span> ＆ </span>
          <a href="https://www.kashika.or.jp/"> 一般社団法人 都市構造可視化推進機構</a>
        </Footer>
    </div>
  );
};

export default HomePage;

